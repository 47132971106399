<template>
  <div>
    <!-- For Free Text -->
    <div v-html="data.questionFreeText"></div>

    <br />
    <br />

    <!-- For uploading image -->
    <div v-for="(file, f) in data.questionFile" :key="f">
      <div class="columns">
        <div class="column is-12">
          <!-- For Displaying Image -->
          <img
            v-lazy="getUrlFile(file.url)"
            alt="Image"
            v-if="type == 'detail' || $route.name == 'editAssessmentSection'"
          />
          <img v-lazy="file.url" alt="Image" v-else-if="type == 'form'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    type: {
      default: () => {},
    },
  },
  methods: {
    getUrlFile(file) {
      return process.env.VUE_APP_BACKEND_URL + file
    },
  },
}
</script>

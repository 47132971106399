<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <template v-if="data.optionRules == 'Regular Button'">
          <ValidationProvider
            :vid="`radiobuttontype${indexSection}${indexAssessment}`"
            name="Radio Button"
            :rules="`${data.require ? 'required' : ''}`"
            v-slot="{ errors }"
          >
            <div :style="differentiateStyle(optionData)">
              <div
                v-for="(option, indexOption) in optionData"
                :key="indexOption"
              >
                <div class="columns">
                  <div class="column is-12">
                    <template v-if="option.type == 'text'">
                      <b-radio
                        type="is-hcc"
                        v-model="radioButtonSelected"
                        :native-value="option.optionId"
                        @input="checkRulesRadioButton(option.optionId)"
                      >
                        {{ option.title }}
                      </b-radio>
                    </template>
                    <template v-else-if="option.type == 'image'">
                      <b-radio
                        type="is-hcc"
                        v-model="radioButtonSelected"
                        :native-value="option.optionId"
                        @input="checkRulesRadioButton(option.optionId)"
                      >
                        <img
                          v-lazy="getUrlFile(option.fileUrl)"
                          alt="Image"
                          style="width: auto"
                        />
                      </b-radio>
                    </template>
                  </div>
                </div>
              </div>
              <span class="required">{{ errors[0] }}</span>
            </div>
          </ValidationProvider>
        </template>

        <template v-else-if="data.optionRules == 'Text Inside Button'">
          <ValidationProvider
            :vid="`radiobuttontype${indexSection}${indexAssessment}`"
            name="Radio Button"
            :rules="`${data.require ? 'required' : ''}`"
            v-slot="{ errors }"
          >
            <div class="columns is-multiline image-selector">
              <div
                v-for="(option, indexOption) in optionData"
                :key="indexOption"
                :class="option.type == 'text' ? 'column' : 'column is-narrow'"
              >
                <template v-if="option.type == 'text'">
                  <!-- <span style="word-wrap: break-word"> -->
                  <b-radio-button
                    type="is-hcc"
                    size="is-medium"
                    outlined
                    expanded
                    v-model="radioButtonSelected"
                    :native-value="option.optionId"
                    @input="checkRulesRadioButton(option.optionId)"
                  >
                    {{ option.title }}
                  </b-radio-button>
                  <!-- </span> -->
                </template>
                <template v-else-if="option.type == 'image'">
                  <input
                    :id="option.optionId"
                    type="radio"
                    v-model="radioButtonSelected"
                    :value="option.optionId"
                    @input="checkRulesRadioButton(option.optionId)"
                  />
                  <label
                    class="my-radio-image"
                    :for="option.optionId"
                    style="
                      max-height: 300px;
                      max-width: 300px;
                      object-fit: cover;
                      background-size: auto;
                    "
                    :style="
                      getSizeImage(option.optionId) >= 300
                        ? 'width: 100%;'
                        : 'width: auto;'
                    "
                  >
                    <img
                      :id="`image-${option.optionId}`"
                      v-lazy="getUrlFile(option.fileUrl)"
                      alt=""
                      style="width: auto; max-width: 100%"
                    />
                  </label>
                  <!-- <b-radio-button
                    type="is-hcc"
                    size="is-medium"
                    outlined
                    expanded
                    v-model="radioButtonSelected"
                    :native-value="option.optionId"
                    @input="checkRulesRadioButton()"
                    style="display:inline-block"
                  >
                    <img
                      v-lazy="getUrlFile(option.fileUrl)"
                      alt="Image"
                      style="float:left;margin-top:14em; max-height: 300px; width: 100%; object-fit: cover;"
                    />
                  </b-radio-button> -->
                </template>
              </div>
            </div>
            <span class="required">{{ errors[0] }}</span>
          </ValidationProvider>
        </template>
      </div>

      <!-- For True Answer -->
      <template
        v-if="
          title == 'Introduction Test' &&
            rulesData.multipleChoiceType == 'Pass/Fail' &&
            this.data.answerOption[0] != null
        "
      >
        <div v-if="isAnswerTrue">
          <div v-html="data.textTrueAnswer"></div>
        </div>
        <!-- For false answer -->
        <div v-else-if="!isAnswerTrue && radioButtonSelected != null">
          <div v-html="data.textFalseAnswer"></div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    rulesData: {
      default: () => {},
    },
    title: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexAssessment: {
      default: () => {},
    },
  },
  data() {
    return {
      radioButtonSelected: null,
      isAnswerTrue: false,
      optionData: [],
      image: {
        width: null,
        height: null,
      },
    }
  },
  mounted() {
    if (this.rulesData.randomType != null) {
      if (this.rulesData.randomType[2] == true) {
        this.randomOptionArray()
      } else {
        this.optionData = this.data.option
      }
    } else {
      this.optionData = this.data.option
    }
  },
  methods: {
    getUrlFile(file) {
      let urlType = file.split(':')

      if (
        (this.$route.name == 'editAssessmentSection' ||
          this.$route.name == 'showAssessmentSection') &&
        urlType[0] != 'blob'
      ) {
        return process.env.VUE_APP_BACKEND_URL + file
      }

      return file
    },
    getSizeImage(id) {
      let image = document.getElementById('image-' + id)

      if (image != null) {
        image = image.naturalWidth
      }

      return image
    },
    checkRulesRadioButton(id) {
      this.radioButtonSelected = id
      if (this.radioButtonSelected == this.data.answerOption[0]) {
        this.isAnswerTrue = true
      } else {
        this.isAnswerTrue = false
      }
    },
    randomOptionArray() {
      let list = JSON.parse(JSON.stringify(this.data.option))
      list = list.sort(() => Math.random() - 0.5)

      this.optionData = list
    },
    differentiateStyle(arr) {
      let type = arr[0].type
      if (type == 'image') {
        return 'display:flex'
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped>
.image-selector div input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.image-selector div input:active + .my-radio-image {
  opacity: 0.9;
}
.image-selector div input:checked + .my-radio-image {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
  border: 5px solid #00a3a3;
  height: auto;
}
.b-radio.radio.is-medium {
  height: auto;
  font-size: 1.25rem;
  white-space: normal;
}
.my-radio-image {
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  width: 100px;
  height: 70px;
  -webkit-transition: all 100ms ease-in;
  -moz-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  /* -webkit-filter: grayscale(1) opacity(0.7);
  -moz-filter: grayscale(1) opacity(0.7);
  filter: grayscale(1) opacity(0.7); */
}
/* @media screen and (min-width: 1216px) {
  .my-radio-image {
    height: 300px !important;
  }
}
@media screen and (min-width: 1408px) {
  .my-radio-image {
    height: 400px !important;
  }
} */
.my-radio-image:hover {
  /* -webkit-filter: grayscale(0.5) opacity(0.9);
  -moz-filter: grayscale(0.5) opacity(0.9);
  filter: grayscale(0.5) opacity(0.9); */
  border: 5px solid #00a3a3;
  height: auto;
}

/* Extras */
a:visited {
  color: #888;
}
a {
  color: #444;
  text-decoration: none;
}
p {
  margin-bottom: 0.3em;
}

.image-selector div input {
  margin: 5px 0 0 12px;
}
.image-selector div label {
  margin-left: 7px;
}
span.cc {
  color: #6d84b4;
}
</style>

<template>
  <div>
    <template v-if="title == 'Question Test'">
      <template v-if="data.textRules.type == 'text'">
        <template v-if="data.textRules.typeLength == 'character'">
          <!-- For text -->
          <template v-if="data.textRules.min > 0">
            <span style="font-size: 12px; padding-right: 10px">
              Min Character : {{ data.textRules.min }}
            </span>
          </template>
          <template v-if="data.textRules.max > 0">
            <span style="font-size: 12px">
              Max Character : {{ data.textRules.max }}
            </span>
          </template>

          <!-- FOr input field -->
          <template v-if="data.textRules.min == 0 && data.textRules.max == 0">
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="Text"
              :rules="{ required: data.require }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input type="textarea" v-model="answerText"></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
          <template
            v-else-if="data.textRules.min > 0 && data.textRules.max > 0"
          >
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="Text"
              :rules="{ required: data.require, betweenchar: data.textRules }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input type="textarea" v-model="answerText"></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
          <template v-else-if="data.textRules.min > 0">
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="Text"
              :rules="{ required: data.require, minchar: data.textRules.min }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input type="textarea" v-model="answerText"></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
          <template v-else-if="data.textRules.max > 0">
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="Text"
              :rules="{ required: data.require, maxchar: data.textRules.max }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input type="textarea" v-model="answerText"></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
        </template>
        <template v-else-if="data.textRules.typeLength == 'word'">
          <!-- For text -->
          <template v-if="data.textRules.min > 0">
            <span style="font-size: 12px; padding-right: 10px">
              Min Word : {{ data.textRules.min }}
            </span>
          </template>
          <template v-if="data.textRules.max > 0">
            <span style="font-size: 12px">
              Max Word : {{ data.textRules.max }}
            </span>
          </template>

          <!-- For input field -->
          <template v-if="data.textRules.min == 0 && data.textRules.max == 0">
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="Text"
              :rules="{ required: data.require }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input type="textarea" v-model="answerText"></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
          <template
            v-else-if="data.textRules.min > 0 && data.textRules.max > 0"
          >
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="text"
              :rules="{ required: data.require, betweenword: data.textRules }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input type="textarea" v-model="answerText"></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
          <template v-else-if="data.textRules.min > 0">
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="Text"
              :rules="{ required: data.require, minword: data.textRules.min }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input type="textarea" v-model="answerText"></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
          <template v-else-if="data.textRules.max > 0">
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="Text"
              :rules="{ required: data.require, maxword: data.textRules.max }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input type="textarea" v-model="answerText"></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
        </template>
      </template>
      <template v-else-if="data.textRules.type == 'email'">
        <ValidationProvider
          :vid="`emailType${indexSection}${indexAssessment}`"
          name="Email"
          :rules="{ required: data.require, email }"
          v-slot="{ errors }"
        >
          <b-field :type="errors.length > 0 ? 'is-danger' : null">
            <b-input type="email" v-model="answerEmail"></b-input>
          </b-field>
          <span class="required">{{ errors[0] }}</span>
        </ValidationProvider>
      </template>
      <template v-else-if="data.textRules.type == 'number'">
        <ValidationProvider
          :vid="`numberType${indexSection}${indexAssessment}`"
          name="Number"
          :rules="{ required: data.require }"
          v-slot="{ errors }"
        >
          <b-field :type="errors.length > 0 ? 'is-danger' : null">
            <b-input type="number" v-model="answerNumber"></b-input>
          </b-field>
          <span class="required">{{ errors[0] }}</span>
        </ValidationProvider>
      </template>
    </template>
    <template v-else-if="title == 'Introduction Test'">
      {{ data.answer }}
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    title: {
      default: () => {},
    },
    textfield: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexAssessment: {
      default: () => {},
    },
  },
  data() {
    return {
      answerText: '',
      answerEmail: '',
      answerNumber: 0,
    }
  },
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.title == 'Question Test')?[(_vm.data.textRules.type == 'text')?[(_vm.data.textRules.typeLength == 'character')?[(_vm.data.textRules.min > 0)?[_c('span',{staticStyle:{"font-size":"12px","padding-right":"10px"}},[_vm._v(" Min Character : "+_vm._s(_vm.data.textRules.min)+" ")])]:_vm._e(),(_vm.data.textRules.max > 0)?[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Max Character : "+_vm._s(_vm.data.textRules.max)+" ")])]:_vm._e(),(_vm.data.textRules.min == 0 && _vm.data.textRules.max == 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"Text","rules":{ required: _vm.data.require }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.answerText),callback:function ($$v) {_vm.answerText=$$v},expression:"answerText"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3662793662)})]:(_vm.data.textRules.min > 0 && _vm.data.textRules.max > 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"Text","rules":{ required: _vm.data.require, betweenchar: _vm.data.textRules }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.answerText),callback:function ($$v) {_vm.answerText=$$v},expression:"answerText"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3662793662)})]:(_vm.data.textRules.min > 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"Text","rules":{ required: _vm.data.require, minchar: _vm.data.textRules.min }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.answerText),callback:function ($$v) {_vm.answerText=$$v},expression:"answerText"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3662793662)})]:(_vm.data.textRules.max > 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"Text","rules":{ required: _vm.data.require, maxchar: _vm.data.textRules.max }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.answerText),callback:function ($$v) {_vm.answerText=$$v},expression:"answerText"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3662793662)})]:_vm._e()]:(_vm.data.textRules.typeLength == 'word')?[(_vm.data.textRules.min > 0)?[_c('span',{staticStyle:{"font-size":"12px","padding-right":"10px"}},[_vm._v(" Min Word : "+_vm._s(_vm.data.textRules.min)+" ")])]:_vm._e(),(_vm.data.textRules.max > 0)?[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Max Word : "+_vm._s(_vm.data.textRules.max)+" ")])]:_vm._e(),(_vm.data.textRules.min == 0 && _vm.data.textRules.max == 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"Text","rules":{ required: _vm.data.require }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.answerText),callback:function ($$v) {_vm.answerText=$$v},expression:"answerText"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3662793662)})]:(_vm.data.textRules.min > 0 && _vm.data.textRules.max > 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"text","rules":{ required: _vm.data.require, betweenword: _vm.data.textRules }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.answerText),callback:function ($$v) {_vm.answerText=$$v},expression:"answerText"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3662793662)})]:(_vm.data.textRules.min > 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"Text","rules":{ required: _vm.data.require, minword: _vm.data.textRules.min }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.answerText),callback:function ($$v) {_vm.answerText=$$v},expression:"answerText"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3662793662)})]:(_vm.data.textRules.max > 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"Text","rules":{ required: _vm.data.require, maxword: _vm.data.textRules.max }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},model:{value:(_vm.answerText),callback:function ($$v) {_vm.answerText=$$v},expression:"answerText"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3662793662)})]:_vm._e()]:_vm._e()]:(_vm.data.textRules.type == 'email')?[_c('ValidationProvider',{attrs:{"vid":("emailType" + _vm.indexSection + _vm.indexAssessment),"name":"Email","rules":{ required: _vm.data.require, email: _vm.email }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"email"},model:{value:(_vm.answerEmail),callback:function ($$v) {_vm.answerEmail=$$v},expression:"answerEmail"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2165513641)})]:(_vm.data.textRules.type == 'number')?[_c('ValidationProvider',{attrs:{"vid":("numberType" + _vm.indexSection + _vm.indexAssessment),"name":"Number","rules":{ required: _vm.data.require }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"number"},model:{value:(_vm.answerNumber),callback:function ($$v) {_vm.answerNumber=$$v},expression:"answerNumber"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3498608649)})]:_vm._e()]:(_vm.title == 'Introduction Test')?[_vm._v(" "+_vm._s(_vm.data.answer)+" ")]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
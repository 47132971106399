<template>
  <div class="modal-card">
    <ValidationObserver ref="form">
      <form @submit.prevent="testAnswer">
        <!-- For Header -->
        <header class="modal-card-head">
          <p class="modal-card-title">PREVIEW</p>
        </header>

        <!-- For Body -->
        <section
          class="modal-card-body"
          style="max-height: 500px; overflow: scroll;"
        >
          <!-- FOr Introduction -->
          <template v-if="title == 'Introduction Test'">
            <div class="box question">
              <div v-html="data.introduction"></div>
            </div>
          </template>

          <span
            v-for="(section, indexSection) in sectionData"
            :key="indexSection"
            class="section"
          >
            <template v-if="section.isUseQuestion">
              <div class="box question">
                <div class="columns is-12">
                  <div class="column is-12">
                    <template v-if="section.questionType == 'text'">
                      <QuestionTypeText :data="section" />
                    </template>

                    <!-- For Image Type -->
                    <template v-else-if="section.questionType == 'image'">
                      <QuestionTypeImage :data="section" :type="type" />
                    </template>

                    <!-- For Video Type -->
                    <template v-else-if="section.questionType == 'video'">
                      <QuestionTypeVideo :data="section" :type="type" />
                    </template>

                    <!-- For Youtube Link Type -->
                    <template v-else-if="section.questionType == 'youtube'">
                      <QuestionTypeYoutubeLink :data="section" :type="type" />
                    </template>

                    <!-- For Free Text Type -->
                    <template v-if="section.questionType == 'free_text'">
                      <QuestionTypeFreeText :data="section" />
                    </template>

                    <!-- For Image And Text Type -->
                    <template v-else-if="section.questionType == 'text_image'">
                      <QuestionTypeTextImage :data="section" :type="type" />
                    </template>

                    <!-- For Video And Text Type -->
                    <template v-else-if="section.questionType == 'text_video'">
                      <QuestionTypeTextVideo :data="section" :type="type" />
                    </template>

                    <!-- For Youtube Link And Text Type -->
                    <template
                      v-else-if="section.questionType == 'text_youtube'"
                    >
                      <QuestionTypeTextYoutubeLink
                        :data="section"
                        :type="type"
                      />
                    </template>

                    <!-- For Image And Free Text Type -->
                    <template
                      v-else-if="section.questionType == 'free_text_image'"
                    >
                      <QuestionTypeFreeTextImage :data="section" :type="type" />
                    </template>

                    <!-- For Video And Free Text Type -->
                    <template
                      v-else-if="section.questionType == 'free_text_video'"
                    >
                      <QuestionTypeFreeTextVideo :data="section" :type="type" />
                    </template>

                    <!-- For Video And Free Text Type -->
                    <template
                      v-else-if="section.questionType == 'free_text_youtube'"
                    >
                      <QuestionTypeFreeTextYoutubeLink
                        :data="section"
                        :type="type"
                      />
                    </template>

                    <!-- For All Type -->
                    <template v-else-if="section.questionType == 'all'">
                      <QuestionTypeAll :data="section" :type="type" />
                    </template>
                  </div>
                </div>
              </div>
            </template>

            <div
              v-for="(assessment, indexAssessment) in randomAssessment(
                section.assessment
              )"
              :key="indexAssessment"
              class="box question"
            >
              <div class="columns is-12">
                <!-- For title question -->
                <div class="column is-2">
                  <span class="handle">
                    <i class="glyphicon glyphicon-menu-hamburger"></i>
                  </span>
                  <b>#{{ indexAssessment + 1 }}</b>
                </div>

                <!-- For type question -->
                <div class="column is-10">
                  <div class="columns">
                    <div class="column is-12">
                      <!-- For Text Type -->
                      <template v-if="assessment.questionType == 'text'">
                        <QuestionTypeText :data="assessment" />
                      </template>

                      <!-- For Image Type -->
                      <template v-else-if="assessment.questionType == 'image'">
                        <QuestionTypeImage :data="assessment" :type="type" />
                      </template>

                      <!-- For Video Type -->
                      <template v-else-if="assessment.questionType == 'video'">
                        <QuestionTypeVideo :data="assessment" :type="type" />
                      </template>

                      <!-- For Youtube Link Type -->
                      <template
                        v-else-if="assessment.questionType == 'youtube'"
                      >
                        <QuestionTypeYoutubeLink
                          :data="assessment"
                          :type="type"
                        />
                      </template>

                      <!-- For Free Text Type -->
                      <template v-if="assessment.questionType == 'free_text'">
                        <QuestionTypeFreeText :data="assessment" />
                      </template>

                      <!-- For Image And Text Type -->
                      <template
                        v-else-if="assessment.questionType == 'text_image'"
                      >
                        <QuestionTypeTextImage
                          :data="assessment"
                          :type="type"
                        />
                      </template>

                      <!-- For Video And Text Type -->
                      <template
                        v-else-if="assessment.questionType == 'text_video'"
                      >
                        <QuestionTypeTextVideo
                          :data="assessment"
                          :type="type"
                        />
                      </template>

                      <!-- For Youtube Link And Text Type -->
                      <template
                        v-else-if="assessment.questionType == 'text_youtube'"
                      >
                        <QuestionTypeTextYoutubeLink
                          :data="assessment"
                          :type="type"
                        />
                      </template>

                      <!-- For Image And Free Text Type -->
                      <template
                        v-else-if="assessment.questionType == 'free_text_image'"
                      >
                        <QuestionTypeFreeTextImage
                          :data="assessment"
                          :type="type"
                        />
                      </template>

                      <!-- For Video And Free Text Type -->
                      <template
                        v-else-if="assessment.questionType == 'free_text_video'"
                      >
                        <QuestionTypeFreeTextVideo
                          :data="assessment"
                          :type="type"
                        />
                      </template>

                      <!-- For Video And Free Text Type -->
                      <template
                        v-else-if="
                          assessment.questionType == 'free_text_youtube'
                        "
                      >
                        <QuestionTypeFreeTextYoutubeLink
                          :data="assessment"
                          :type="type"
                        />
                      </template>

                      <!-- For All Type -->
                      <template v-else-if="assessment.questionType == 'all'">
                        <QuestionTypeAll :data="assessment" :type="type" />
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <!-- For answer quetion -->
              <div class="columns is-12" v-if="assessment.type != 'question'">
                <div class="column is-2">
                  <b>Answer</b>
                </div>

                <div class="column is-10">
                  <!-- *Note: Textfield dan Texarea ditukar sementara -->
                  <!-- For Type Textfield -->
                  <template v-if="assessment.type == 'textfield'">
                    <AnswerTypeTextarea
                      :data="assessment"
                      :title="title"
                      :indexSection="indexSection"
                      :indexAssessment="indexAssessment"
                      :textfield="textfield"
                    />
                  </template>

                  <!-- For Type Textarea -->
                  <!-- <template v-else-if="assessment.type == 'textarea'">
                <AnswerTypeTextarea :data="assessment" :title="title" />
                  </template>-->

                  <!-- For Type Multiple Choice -->
                  <template v-else-if="assessment.type == 'radio-button'">
                    <AnswerTypeRadioButton
                      :data="assessment"
                      :indexSection="indexSection"
                      :indexAssessment="indexAssessment"
                      :rulesData="rulesData"
                      :title="title"
                    />
                  </template>

                  <!-- For Type Checkbox -->
                  <template v-else-if="assessment.type == 'checkbox'">
                    <AnswerTypeCheckbox
                      :data="assessment"
                      :indexSection="indexSection"
                      :indexAssessment="indexAssessment"
                      :rulesData="rulesData"
                      :title="title"
                    />
                  </template>

                  <!-- For Type Linear Scale -->
                  <template v-else-if="assessment.type == 'linear-scale'">
                    <AnswerTypeLinearScale
                      :data="assessment"
                      :indexSection="indexSection"
                      :indexAssessment="indexAssessment"
                      :linearScale="linearScale"
                    />
                  </template>

                  <!-- For Type Ranking -->
                  <template v-else-if="assessment.type == 'ranking'">
                    <AnswerTypeRanking
                      :data="assessment"
                      :rulesData="rulesData"
                      :indexSection="indexSection"
                      :indexAssessment="indexAssessment"
                    />
                  </template>

                  <!-- For Type Subsection -->
                  <!-- <template v-else-if="assessment.type == 'subsection'">
                <AnswerTypeSubsection :data="assessment" />
                  </template>-->
                </div>
              </div>
            </div>
          </span>
        </section>

        <!-- For Footer -->
        <footer class="modal-card-foot">
          <button
            class="button is-danger"
            type="button"
            @click="$parent.close()"
          >
            Close
          </button>
          <button class="button is-hcc" native-type="submit">Save</button>
        </footer>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
// For Question Vue Component
import QuestionTypeText from './question-type/QuestionTypeText'
import QuestionTypeImage from './question-type/QuestionTypeImage'
import QuestionTypeVideo from './question-type/QuestionTypeVideo'
import QuestionTypeYoutubeLink from './question-type/QuestionTypeYoutubeLink'
import QuestionTypeFreeText from './question-type/QuestionTypeFreeText'
import QuestionTypeTextImage from './question-type/QuestionTypeTextImage'
import QuestionTypeTextVideo from './question-type/QuestionTypeTextVideo'
import QuestionTypeTextYoutubeLink from './question-type/QuestionTypeTextYoutubeLink'
import QuestionTypeFreeTextImage from './question-type/QuestionTypeFreeTextImage'
import QuestionTypeFreeTextVideo from './question-type/QuestionTypeFreeTextVideo'
import QuestionTypeFreeTextYoutubeLink from './question-type/QuestionTypeFreeTextYoutubeLink'
import QuestionTypeAll from './question-type/QuestionTypeAll'

// For Answer Vue Component
// import AnswerTypeTextfield from "./answer-type/AnswerTypeTextfield";
import AnswerTypeTextarea from './answer-type/AnswerTypeTextarea'
import AnswerTypeRadioButton from './answer-type/AnswerTypeRadioButton'
import AnswerTypeCheckbox from './answer-type/AnswerTypeCheckbox'
import AnswerTypeLinearScale from './answer-type/AnswerTypeLinearScale'
import AnswerTypeRanking from './answer-type/AnswerTypeRanking'

export default {
  components: {
    // Using Question Component
    QuestionTypeText,
    QuestionTypeImage,
    QuestionTypeVideo,
    QuestionTypeYoutubeLink,
    QuestionTypeFreeText,
    QuestionTypeTextImage,
    QuestionTypeTextVideo,
    QuestionTypeTextYoutubeLink,
    QuestionTypeFreeTextImage,
    QuestionTypeFreeTextVideo,
    QuestionTypeFreeTextYoutubeLink,
    QuestionTypeAll,

    // Using Answer COmponent
    // AnswerTypeTextfield,
    AnswerTypeTextarea,
    AnswerTypeRadioButton,
    AnswerTypeCheckbox,
    AnswerTypeLinearScale,
    AnswerTypeRanking,
  },
  props: {
    data: {
      default: () => {},
    },
    rulesData: {
      default: () => {},
    },
    type: {
      default: () => {},
    },
    title: {
      default: () => {},
    },
  },
  data() {
    return {
      linearScale: {
        option: null,
      },
      textfield: {
        text: '',
        email: '',
        number: '',
      },
      assessmentData: [],
      sectionData: [],
    }
  },
  mounted() {
    if (this.title == 'Question Test' && this.rulesData.randomType[0] == true) {
      this.randomQuestionGroup()
    } else {
      this.sectionData = this.data.section
    }
  },
  methods: {
    testAnswer() {
      this.$refs.form.validate().then(success => {
        if (!success) {
          this.danger('please fill all required fields')
        } else {
          this.success('Success')
        }
      })
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
    randomQuestionGroup() {
      let arrayRandom = JSON.parse(JSON.stringify(this.data.section)).sort(
        () => Math.random() - 0.5
      )

      let arrayReturn = []
      if (this.rulesData.randomType[0] == true) {
        if (this.rulesData.randomQuestionGroupNumber > 0) {
          arrayRandom.map(array => {
            if (arrayReturn.length < this.rulesData.randomQuestionGroupNumber) {
              arrayReturn.push(array)
            }
          })
        } else {
          arrayRandom.map(array => {
            arrayReturn.push(array)
          })
        }
      }
      this.sectionData = arrayReturn
    },
    randomAssessment(assessment) {
      let arrayData = assessment

      let arrayReturn = []
      if (this.title == 'Question Test') {
        if (this.rulesData.randomType[1] == true) {
          arrayData = JSON.parse(JSON.stringify(arrayData)).sort(
            () => Math.random() - 0.5
          )

          if (this.rulesData.randomType[0] != true) {
            if (this.rulesData.randomQuestionNumber > 0) {
              arrayData.map(array => {
                if (arrayReturn.length < this.rulesData.randomQuestionNumber) {
                  arrayReturn.push(array)
                }
              })
            } else {
              arrayData.map(array => {
                arrayReturn.push(array)
              })
            }
          } else {
            arrayData.map(array => {
              arrayReturn.push(array)
            })
          }
        } else {
          arrayReturn = arrayData
        }
      } else {
        arrayReturn = arrayData
      }

      return arrayReturn
    },
  },
}
</script>

<template>
  <span v-if="data.require == true">
    <span class="message-body-data">
      Klik tahan pilihan dari kotak sebelah kiri, dan pindahkan ke kotak sebelah
      kanan. Setelah itu, urutkan pilihan Anda.
    </span>
    <span
      v-if="
        rulesData.rankingTypeAnswer[0] == false &&
          rulesData.rankingTypeAnswer[1] == true
      "
    >
      <ValidationProvider
        :vid="`rankingtype${indexSection}${indexAssessment}`"
        name="Ranking"
        :rules="
          `requiredarray|checkbox-answermax:${rulesData.rankingMaxAnswer}`
        "
        v-slot="{ errors }"
        class="columns is-multiline"
        style="margin: 0.1px"
      >
        <!-- For list question -->
        <draggable
          class="column is-6 border-ranking-left is-variable list-group"
          :list="rankingOption"
          group="ranking1"
        >
          <div
            v-for="(ranking, indexRanking) in rankingOption"
            :key="indexRanking"
            style="padding-bottom: 2%"
          >
            <div class="box-answer">
              <div class="columns">
                <div class="column is-11" style="word-wrap: break-word">
                  {{ ranking.option }}
                </div>
                <div class="column is-1" style="margin: auto">
                  <b-icon
                    size="is-small"
                    icon="share"
                    style="vertical-align: middle"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </draggable>

        <!-- For list answer -->
        <draggable
          v-model="answerRanking"
          class="column is-6 border-ranking-right is-variable list-group"
          group="ranking1"
        >
          <div
            v-for="(ranking, indexRanking) in answerRanking"
            :key="indexRanking"
            style="padding-bottom: 2%"
          >
            <div class="box-answer">
              <div class="columns">
                <div class="column is-11" style="word-wrap: break-word">
                  {{ indexRanking + 1 }}. {{ ranking.option }}
                </div>
                <div class="column is-1" style="margin: auto">
                  <b-icon
                    size="is-small"
                    icon="sort"
                    style="vertical-align: middle"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </draggable>
        <span class="required column is-12">{{ errors[0] }}</span>
      </ValidationProvider>
    </span>
    <span
      v-else-if="
        rulesData.rankingTypeAnswer[0] == true &&
          rulesData.rankingTypeAnswer[1] == false
      "
    >
      <ValidationProvider
        :vid="`rankingtype${indexSection}${indexAssessment}`"
        name="Ranking"
        :rules="
          `requiredarray|checkbox-answermin:${rulesData.rankingMinAnswer}`
        "
        v-slot="{ errors }"
        class="columns is-multiline"
        style="margin: 0.1px"
      >
        <!-- For list question -->
        <draggable
          class="column is-6 border-ranking-left is-variable list-group"
          :list="rankingOption"
          group="ranking2"
        >
          <div
            v-for="(ranking, indexRanking) in rankingOption"
            :key="indexRanking"
            style="padding-bottom: 2%"
          >
            <div class="box-answer">
              <div class="columns">
                <div class="column is-11" style="word-wrap: break-word">
                  {{ ranking.option }}
                </div>
                <div class="column is-1" style="margin: auto">
                  <b-icon
                    size="is-small"
                    icon="share"
                    style="vertical-align: middle"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </draggable>

        <!-- For list answer -->
        <draggable
          v-model="answerRanking"
          class="column is-6 border-ranking-right is-variable list-group"
          group="ranking2"
        >
          <div
            v-for="(ranking, indexRanking) in answerRanking"
            :key="indexRanking"
            style="padding-bottom: 2%"
          >
            <div class="box-answer">
              <div class="columns">
                <div class="column is-11" style="word-wrap: break-word">
                  {{ indexRanking + 1 }}. {{ ranking.option }}
                </div>
                <div class="column is-1" style="margin: auto">
                  <b-icon
                    size="is-small"
                    icon="sort"
                    style="vertical-align: middle"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </draggable>
        <span class="required column is-12">{{ errors[0] }}</span>
      </ValidationProvider>
    </span>
    <span
      v-else-if="
        rulesData.rankingTypeAnswer[0] == true &&
          rulesData.rankingTypeAnswer[1] == true
      "
    >
      <ValidationProvider
        :vid="`rankingtype${indexSection}${indexAssessment}`"
        name="Ranking"
        :rules="
          `requiredarray|requiredarraybetween:${rulesData.rankingMinAnswer},${rulesData.rankingMaxAnswer}`
        "
        v-slot="{ errors }"
        class="columns is-multiline"
        style="margin: 0.1px"
      >
        <!-- For list question -->
        <draggable
          class="column is-6 border-ranking-left is-variable list-group"
          :list="rankingOption"
          group="ranking3"
        >
          <div
            v-for="(ranking, indexRanking) in rankingOption"
            :key="indexRanking"
            style="padding-bottom: 2%"
          >
            <div class="box-answer">
              <div class="columns">
                <div class="column is-11" style="word-wrap: break-word;">
                  {{ ranking.option }}
                </div>
                <div class="column is-1" style="margin: auto">
                  <b-icon
                    size="is-small"
                    icon="share"
                    style="vertical-align: middle"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </draggable>

        <!-- For list answer -->
        <draggable
          v-model="answerRanking"
          class="column is-6 border-ranking-right is-variable list-group"
          group="ranking3"
        >
          <div
            v-for="(ranking, indexRanking) in answerRanking"
            :key="indexRanking"
            style="padding-bottom: 2%"
          >
            <div class="box-answer">
              <div class="columns">
                <div class="column is-11" style="word-wrap: break-word">
                  {{ indexRanking + 1 }}. {{ ranking.option }}
                </div>
                <div class="column is-1" style="margin: auto">
                  <b-icon
                    size="is-small"
                    icon="sort"
                    style="vertical-align: middle"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </draggable>
        <span class="required column is-12">{{ errors[0] }}</span>
      </ValidationProvider>
    </span>
  </span>
  <span v-else>
    <span class="message-body-data">
      Klik tahan pilihan dari kotak sebelah kiri, dan pindahkan ke kotak sebelah
      kanan. Setelah itu, urutkan pilihan Anda.
    </span>
    <span
      v-if="
        rulesData.rankingTypeAnswer[0] == false &&
          rulesData.rankingTypeAnswer[1] == true
      "
    >
      <ValidationProvider
        :vid="`rankingtype${indexSection}${indexAssessment}`"
        name="Ranking"
        :rules="`checkbox-answermax:${rulesData.rankingMaxAnswer}`"
        v-slot="{ errors }"
        class="columns is-multiline"
        style="margin: 0.1px"
      >
        <!-- For list question -->
        <draggable
          class="column is-6 border-ranking-left is-variable list-group"
          :list="rankingOption"
          group="ranking4"
        >
          <div
            v-for="(ranking, indexRanking) in rankingOption"
            :key="indexRanking"
            style="padding-bottom: 2%"
          >
            <div class="box-answer">
              <div class="columns">
                <div class="column is-11" style="word-wrap: break-word">
                  {{ ranking.option }}
                </div>
                <div class="column is-1" style="margin: auto">
                  <b-icon
                    size="is-small"
                    icon="share"
                    style="vertical-align: middle"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </draggable>

        <!-- For list answer -->
        <draggable
          v-model="answerRanking"
          class="column is-6 border-ranking-right is-variable list-group"
          group="ranking4"
        >
          <div
            v-for="(ranking, indexRanking) in answerRanking"
            :key="indexRanking"
            style="padding-bottom: 2%"
          >
            <div class="box-answer">
              <div class="columns">
                <div class="column is-11" style="word-wrap: break-word">
                  {{ indexRanking + 1 }}. {{ ranking.option }}
                </div>
                <div class="column is-1" style="margin: auto">
                  <b-icon
                    size="is-small"
                    icon="sort"
                    style="vertical-align: middle"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </draggable>
        <span class="required column is-12">{{ errors[0] }}</span>
      </ValidationProvider>
    </span>
    <span
      v-else-if="
        rulesData.rankingTypeAnswer[0] == true &&
          rulesData.rankingTypeAnswer[1] == false
      "
    >
      <ValidationProvider
        :vid="`rankingtype${indexSection}${indexAssessment}`"
        name="Ranking"
        :rules="`checkbox-answermin:${rulesData.rankingMinAnswer}`"
        v-slot="{ errors }"
        class="columns is-multiline"
        style="margin: 0.1px"
      >
        <!-- For list question -->
        <draggable
          class="column is-6 border-ranking-left is-variable list-group"
          :list="rankingOption"
          group="ranking5"
        >
          <div
            v-for="(ranking, indexRanking) in rankingOption"
            :key="indexRanking"
            style="padding-bottom: 2%"
          >
            <div class="box-answer">
              <div class="columns">
                <div class="column is-11" style="word-wrap: break-word">
                  {{ ranking.option }}
                </div>
                <div class="column is-1" style="margin: auto">
                  <b-icon
                    size="is-small"
                    icon="share"
                    style="vertical-align: middle"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </draggable>

        <!-- For list answer -->
        <draggable
          v-model="answerRanking"
          class="column is-6 border-ranking-right is-variable list-group"
          group="ranking5"
        >
          <div
            v-for="(ranking, indexRanking) in answerRanking"
            :key="indexRanking"
            style="padding-bottom: 2%"
          >
            <div class="box-answer">
              <div class="columns">
                <div class="column is-11" style="word-wrap: break-word">
                  {{ indexRanking + 1 }}. {{ ranking.option }}
                </div>
                <div class="column is-1" style="margin: auto">
                  <b-icon
                    size="is-small"
                    icon="sort"
                    style="vertical-align: middle"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </draggable>
        <span class="required column is-12">{{ errors[0] }}</span>
      </ValidationProvider>
    </span>
    <span
      v-else-if="
        rulesData.rankingTypeAnswer[0] == true &&
          rulesData.rankingTypeAnswer[1] == true
      "
    >
      <ValidationProvider
        :vid="`rankingtype${indexSection}${indexAssessment}`"
        name="Ranking"
        :rules="
          `requiredarraybetween:${rulesData.rankingMinAnswer},${rulesData.rankingMaxAnswer}`
        "
        v-slot="{ errors }"
        class="columns is-multiline"
        style="margin: 0.1px"
      >
        <!-- For list question -->
        <draggable
          class="column is-6 border-ranking-left is-variable list-group"
          :list="rankingOption"
          group="ranking6"
        >
          <div
            v-for="(ranking, indexRanking) in rankingOption"
            :key="indexRanking"
            style="padding-bottom: 2%"
          >
            <div class="box-answer">
              <div class="columns">
                <div class="column is-11" style="word-wrap: break-word">
                  {{ ranking.option }}
                </div>
                <div class="column is-1" style="margin: auto">
                  <b-icon
                    size="is-small"
                    icon="share"
                    style="vertical-align: middle"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </draggable>

        <!-- For list answer -->
        <draggable
          v-model="answerRanking"
          class="column is-6 border-ranking-right is-variable list-group"
          group="ranking6"
        >
          <div
            v-for="(ranking, indexRanking) in answerRanking"
            :key="indexRanking"
            style="padding-bottom: 2%"
          >
            <div class="box-answer">
              <div class="columns">
                <div class="column is-11" style="word-wrap: break-word">
                  {{ indexRanking + 1 }}. {{ ranking.option }}
                </div>
                <div class="column is-1" style="margin: auto">
                  <b-icon
                    size="is-small"
                    icon="sort"
                    style="vertical-align: middle"
                  ></b-icon>
                </div>
              </div>
            </div>
          </div>
        </draggable>
        <span class="required column is-12">{{ errors[0] }}</span>
      </ValidationProvider>
    </span>
  </span>
</template>

<script>
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },
  props: {
    data: {
      default: () => {}
    },
    rulesData: {
      default: () => {}
    },
    indexSection: {
      default: () => {}
    },
    indexAssessment: {
      default: () => {}
    }
  },
  data() {
    return {
      rankingOption: [],
      answerRanking: []
    };
  },
  mounted() {
    let data = JSON.parse(JSON.stringify(this.data));
    this.rankingOption = data.rankingOption;
  },
  watch: {
    answerRanking() {
      if (this.rulesData.rankingTypeAnswer[1] == true) {
        if (this.answerRanking.length > this.rulesData.rankingMaxAnswer) {
          this.rankingOption.push(
            this.answerRanking[this.answerRanking.length - 1]
          );
          this.answerRanking.splice(this.answerRanking.length - 1);
        }
      }
    }
  }
};
</script>
<style scoped>
.border-ranking-left {
  /* border: 1px solid black; */
  width: 48% !important;
  background-color: #ebebeb;
  margin-right: 2%;
}
.border-ranking-right {
  border: 1px solid #7e7e7e;
  width: 49% !important;
}
.message-body-data {
  color: #7e7e7e;
  font-size: 12px;
}
.box-answer {
  /* border: 1px solid black; */
  margin-right: 3%;
  margin-bottom: 1%;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  color: #4a4a4a;
  padding-top: 0.25px;
  padding-bottom: 0.25px;
  padding-left: 1rem;
  padding-right: 0.5rem;
  /* display: block; */
}
</style>

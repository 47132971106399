var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[(_vm.data.optionRules == 'Regular Button')?[(_vm.data.require == true)?_c('span',[(
              _vm.rulesData.checkboxTypeAnswer[0] == false &&
                _vm.rulesData.checkboxTypeAnswer[1] == true
            )?_c('span',[_c('ValidationProvider',{attrs:{"vid":("checkboxtype" + _vm.indexSection + _vm.indexAssessment),"name":"checkbox","rules":("requiredarray|checkboxmax:" + (_vm.rulesData.checkboxMaxAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{style:(_vm.differentiateStyle(_vm.optionData))},[_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-7"},[(option.type == 'text')?[_c('b-checkbox',{attrs:{"type":"is-hcc","native-value":option.optionId,"disabled":_vm.isDisabledButton &&
                              !_vm.checkboxSelected.includes(option.optionId)},on:{"input":function($event){return _vm.checkRulesCheckbox()}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('b-checkbox',{attrs:{"type":"is-hcc","native-value":option.optionId,"disabled":_vm.isDisabledButton &&
                              !_vm.checkboxSelected.includes(option.optionId)},on:{"input":function($event){return _vm.checkRulesCheckbox()}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],staticStyle:{"width":"auto"},attrs:{"alt":"Image"}})])]:_vm._e()],2)])])}),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])],2)]}}],null,false,4095414120)})],1):_vm._e(),(
              _vm.rulesData.checkboxTypeAnswer[0] == true &&
                _vm.rulesData.checkboxTypeAnswer[1] == false
            )?_c('span',[_c('ValidationProvider',{attrs:{"vid":("checkboxtype" + _vm.indexSection + _vm.indexAssessment),"name":"checkbox","rules":("requiredarray|checkboxmin:" + (_vm.rulesData.checkboxMinAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{style:(_vm.differentiateStyle(_vm.optionData))},[_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-7"},[(option.type == 'text')?[_c('b-checkbox',{attrs:{"type":"is-hcc","native-value":option.optionId,"disabled":_vm.isDisabledButton &&
                              !_vm.checkboxSelected.includes(option.optionId)},on:{"input":function($event){return _vm.checkRulesCheckbox()}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('b-checkbox',{attrs:{"type":"is-hcc","native-value":option.optionId,"disabled":_vm.isDisabledButton &&
                              !_vm.checkboxSelected.includes(option.optionId)},on:{"input":function($event){return _vm.checkRulesCheckbox()}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],staticStyle:{"width":"auto"},attrs:{"alt":"Image"}})])]:_vm._e()],2)])])}),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])],2)]}}],null,false,4095414120)})],1):_vm._e(),(
              _vm.rulesData.checkboxTypeAnswer[0] == true &&
                _vm.rulesData.checkboxTypeAnswer[1] == true
            )?_c('span',[_c('ValidationProvider',{attrs:{"vid":("checkboxtype" + _vm.indexSection + _vm.indexAssessment),"name":"checkbox","rules":("requiredarray|requiredarraybetween:" + (_vm.rulesData.checkboxMinAnswer) + "," + (_vm.rulesData.checkboxMaxAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{style:(_vm.differentiateStyle(_vm.optionData))},[_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-7"},[(option.type == 'text')?[_c('b-checkbox',{attrs:{"type":"is-hcc","native-value":option.optionId,"disabled":_vm.isDisabledButton &&
                              !_vm.checkboxSelected.includes(option.optionId)},on:{"input":function($event){return _vm.checkRulesCheckbox()}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('b-checkbox',{attrs:{"type":"is-hcc","native-value":option.optionId,"disabled":_vm.isDisabledButton &&
                              !_vm.checkboxSelected.includes(option.optionId)},on:{"input":function($event){return _vm.checkRulesCheckbox()}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],staticStyle:{"width":"auto"},attrs:{"alt":"Image"}})])]:_vm._e()],2)])])}),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])],2)]}}],null,false,4095414120)})],1):_vm._e()]):_c('span',[(
              _vm.rulesData.checkboxTypeAnswer[0] == false &&
                _vm.rulesData.checkboxTypeAnswer[1] == true
            )?_c('span',[_c('ValidationProvider',{attrs:{"vid":("checkboxtype" + _vm.indexSection + _vm.indexAssessment),"name":"checkbox","rules":("checkboxmax:" + (_vm.rulesData.checkboxMaxAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{style:(_vm.differentiateStyle(_vm.optionData))},[_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-7"},[(option.type == 'text')?[_c('b-checkbox',{attrs:{"type":"is-hcc","native-value":option.optionId,"disabled":_vm.isDisabledButton &&
                              !_vm.checkboxSelected.includes(option.optionId)},on:{"input":function($event){return _vm.checkRulesCheckbox()}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('b-checkbox',{attrs:{"type":"is-hcc","native-value":option.optionId,"disabled":_vm.isDisabledButton &&
                              !_vm.checkboxSelected.includes(option.optionId)},on:{"input":function($event){return _vm.checkRulesCheckbox()}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],staticStyle:{"width":"auto"},attrs:{"alt":"Image"}})])]:_vm._e()],2)])])}),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])],2)]}}],null,false,4095414120)})],1):_vm._e(),(
              _vm.rulesData.checkboxTypeAnswer[0] == true &&
                _vm.rulesData.checkboxTypeAnswer[1] == false
            )?_c('span',[_c('ValidationProvider',{attrs:{"vid":("checkboxtype" + _vm.indexSection + _vm.indexAssessment),"name":"checkbox","rules":("checkboxmin:" + (_vm.rulesData.checkboxMinAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{style:(_vm.differentiateStyle(_vm.optionData))},[_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[(option.type == 'text')?[_c('b-checkbox',{attrs:{"type":"is-hcc","native-value":option.optionId,"disabled":_vm.isDisabledButton &&
                              !_vm.checkboxSelected.includes(option.optionId)},on:{"input":function($event){return _vm.checkRulesCheckbox()}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('b-checkbox',{attrs:{"type":"is-hcc","native-value":option.optionId,"disabled":_vm.isDisabledButton &&
                              !_vm.checkboxSelected.includes(option.optionId)},on:{"input":function($event){return _vm.checkRulesCheckbox()}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],staticStyle:{"width":"auto"},attrs:{"alt":"Image"}})])]:_vm._e()],2)])])}),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])],2)]}}],null,false,1174698396)})],1):_vm._e(),(
              _vm.rulesData.checkboxTypeAnswer[0] == true &&
                _vm.rulesData.checkboxTypeAnswer[1] == true
            )?_c('span',[_c('ValidationProvider',{attrs:{"vid":("checkboxtype" + _vm.indexSection + _vm.indexAssessment),"name":"checkbox","rules":("requiredarraybetween:" + (_vm.rulesData.checkboxMinAnswer) + "," + (_vm.rulesData.checkboxMaxAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{style:(_vm.differentiateStyle(_vm.optionData))},[_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[(option.type == 'text')?[_c('b-checkbox',{attrs:{"type":"is-hcc","native-value":option.optionId,"disabled":_vm.isDisabledButton &&
                              !_vm.checkboxSelected.includes(option.optionId)},on:{"input":function($event){return _vm.checkRulesCheckbox()}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('b-checkbox',{attrs:{"type":"is-hcc","native-value":option.optionId,"disabled":_vm.isDisabledButton &&
                              !_vm.checkboxSelected.includes(option.optionId)},on:{"input":function($event){return _vm.checkRulesCheckbox()}},model:{value:(_vm.checkboxSelected),callback:function ($$v) {_vm.checkboxSelected=$$v},expression:"checkboxSelected"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],staticStyle:{"width":"auto"},attrs:{"alt":"Image"}})])]:_vm._e()],2)])])}),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])],2)]}}],null,false,1174698396)})],1):_vm._e()])]:(_vm.data.optionRules == 'Text Inside Button')?[(_vm.data.require == true)?_c('span',[(
              _vm.rulesData.checkboxTypeAnswer[0] == false &&
                _vm.rulesData.checkboxTypeAnswer[1] == true
            )?_c('span',[_c('ValidationProvider',{attrs:{"vid":("checkboxtype" + _vm.indexSection + _vm.indexAssessment),"name":"checkbox","rules":("requiredarray|checkboxmax:" + (_vm.rulesData.checkboxMaxAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"columns image-selector is-multiline"},_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption,class:option.type == 'text' ? 'column' : 'column is-narrow'},[(option.type == 'text')?[_c('b-radio-button',{attrs:{"type":"is-hcc","size":"is-medium","outlined":"","expanded":"","native-value":option.optionId},on:{"copy":function($event){return _vm.copyText()},"input":function($event){return _vm.checkRulesRadioButton()}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkboxSelected),expression:"checkboxSelected"}],staticStyle:{"margin-right":"10px"},attrs:{"id":option.optionId,"type":"checkbox","disabled":_vm.isDisabledButton &&
                          !_vm.checkboxSelected.includes(option.optionId)},domProps:{"value":option.optionId,"checked":Array.isArray(_vm.checkboxSelected)?_vm._i(_vm.checkboxSelected,option.optionId)>-1:(_vm.checkboxSelected)},on:{"input":function($event){return _vm.checkRulesCheckbox(option.optionId, indexOption)},"change":function($event){var $$a=_vm.checkboxSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option.optionId,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkboxSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkboxSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkboxSelected=$$c}}}}),_c('label',{staticClass:"my-radio-image",staticStyle:{"max-height":"300px","max-width":"300px","object-fit":"cover","background-size":"auto"},style:(_vm.getSizeImage(option.optionId) >= 300
                          ? 'width: 100%;'
                          : 'width: auto;'),attrs:{"for":option.optionId}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],staticStyle:{"width":"auto","max-width":"100%"},attrs:{"id":("image-" + (option.optionId)),"alt":""}})])]:_vm._e()],2)}),0),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3836086880)})],1):_vm._e(),(
              _vm.rulesData.checkboxTypeAnswer[0] == true &&
                _vm.rulesData.checkboxTypeAnswer[1] == false
            )?_c('span',[_c('ValidationProvider',{attrs:{"vid":("checkboxtype" + _vm.indexSection + _vm.indexAssessment),"name":"checkbox","rules":("requiredarray|checkboxmin:" + (_vm.rulesData.checkboxMinAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"columns image-selector is-multiline"},_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption,class:option.type == 'text' ? 'column' : 'column is-narrow'},[(option.type == 'text')?[_c('b-radio-button',{attrs:{"type":"is-hcc","size":"is-medium","outlined":"","expanded":"","native-value":option.optionId},on:{"copy":function($event){return _vm.copyText()},"input":function($event){return _vm.checkRulesRadioButton()}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkboxSelected),expression:"checkboxSelected"}],staticStyle:{"margin-right":"10px"},attrs:{"id":option.optionId,"type":"checkbox","disabled":_vm.isDisabledButton &&
                          !_vm.checkboxSelected.includes(option.optionId)},domProps:{"value":option.optionId,"checked":Array.isArray(_vm.checkboxSelected)?_vm._i(_vm.checkboxSelected,option.optionId)>-1:(_vm.checkboxSelected)},on:{"input":function($event){return _vm.checkRulesCheckbox(option.optionId, indexOption)},"change":function($event){var $$a=_vm.checkboxSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option.optionId,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkboxSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkboxSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkboxSelected=$$c}}}}),_c('label',{staticClass:"my-radio-image",staticStyle:{"max-height":"300px","max-width":"300px","object-fit":"cover","background-size":"auto"},style:(_vm.getSizeImage(option.optionId) >= 300
                          ? 'width: 100%;'
                          : 'width: auto;'),attrs:{"for":option.optionId}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],staticStyle:{"width":"auto","max-width":"100%"},attrs:{"id":("image-" + (option.optionId)),"alt":""}})])]:_vm._e()],2)}),0),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3836086880)})],1):_vm._e(),(
              _vm.rulesData.checkboxTypeAnswer[0] == true &&
                _vm.rulesData.checkboxTypeAnswer[1] == true
            )?_c('span',[_c('ValidationProvider',{attrs:{"vid":("checkboxtype" + _vm.indexSection + _vm.indexAssessment),"name":"checkbox","rules":("requiredarray|requiredarraybetween:" + (_vm.rulesData.checkboxMinAnswer) + "," + (_vm.rulesData.checkboxMaxAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"columns image-selector is-multiline"},_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption,class:option.type == 'text' ? 'column' : 'column is-narrow'},[(option.type == 'text')?[_c('b-radio-button',{attrs:{"type":"is-hcc","size":"is-medium","outlined":"","expanded":"","native-value":option.optionId},on:{"copy":function($event){return _vm.copyText()},"input":function($event){return _vm.checkRulesRadioButton()}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkboxSelected),expression:"checkboxSelected"}],staticStyle:{"margin-right":"10px"},attrs:{"id":option.optionId,"type":"checkbox","disabled":_vm.isDisabledButton &&
                          !_vm.checkboxSelected.includes(option.optionId)},domProps:{"value":option.optionId,"checked":Array.isArray(_vm.checkboxSelected)?_vm._i(_vm.checkboxSelected,option.optionId)>-1:(_vm.checkboxSelected)},on:{"input":function($event){return _vm.checkRulesCheckbox(option.optionId, indexOption)},"change":function($event){var $$a=_vm.checkboxSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option.optionId,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkboxSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkboxSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkboxSelected=$$c}}}}),_c('label',{staticClass:"my-radio-image",staticStyle:{"max-height":"300px","max-width":"300px","object-fit":"cover","background-size":"auto"},style:(_vm.getSizeImage(option.optionId) >= 300
                          ? 'width: 100%;'
                          : 'width: auto;'),attrs:{"for":option.optionId}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],staticStyle:{"width":"auto","max-width":"100%"},attrs:{"id":("image-" + (option.optionId)),"alt":""}})])]:_vm._e()],2)}),0),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3836086880)})],1):_vm._e()]):_c('span',[(
              _vm.rulesData.checkboxTypeAnswer[0] == false &&
                _vm.rulesData.checkboxTypeAnswer[1] == true
            )?_c('span',[_c('ValidationProvider',{attrs:{"vid":("checkboxtype" + _vm.indexSection + _vm.indexAssessment),"name":"checkbox","rules":("checkboxmax:" + (_vm.rulesData.checkboxMaxAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"columns image-selector is-multiline"},_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption,class:option.type == 'text' ? 'column' : 'column is-narrow'},[(option.type == 'text')?[_c('b-radio-button',{attrs:{"type":"is-hcc","size":"is-medium","outlined":"","expanded":"","native-value":option.optionId},on:{"copy":function($event){return _vm.copyText()},"input":function($event){return _vm.checkRulesRadioButton()}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkboxSelected),expression:"checkboxSelected"}],staticStyle:{"margin-right":"10px"},attrs:{"id":option.optionId,"type":"checkbox","disabled":_vm.isDisabledButton &&
                          !_vm.checkboxSelected.includes(option.optionId)},domProps:{"value":option.optionId,"checked":Array.isArray(_vm.checkboxSelected)?_vm._i(_vm.checkboxSelected,option.optionId)>-1:(_vm.checkboxSelected)},on:{"input":function($event){return _vm.checkRulesCheckbox(option.optionId, indexOption)},"change":function($event){var $$a=_vm.checkboxSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option.optionId,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkboxSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkboxSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkboxSelected=$$c}}}}),_c('label',{staticClass:"my-radio-image",staticStyle:{"max-height":"300px","max-width":"300px","object-fit":"cover","background-size":"auto"},style:(_vm.getSizeImage(option.optionId) >= 300
                          ? 'width: 100%;'
                          : 'width: auto;'),attrs:{"for":option.optionId}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],staticStyle:{"width":"auto","max-width":"100%"},attrs:{"id":("image-" + (option.optionId)),"alt":""}})])]:_vm._e()],2)}),0),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3836086880)})],1):_vm._e(),(
              _vm.rulesData.checkboxTypeAnswer[0] == true &&
                _vm.rulesData.checkboxTypeAnswer[1] == false
            )?_c('span',[_c('ValidationProvider',{attrs:{"vid":("checkboxtype" + _vm.indexSection + _vm.indexAssessment),"name":"checkbox","rules":("checkboxmin:" + (_vm.rulesData.checkboxMinAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"columns image-selector is-multiline"},_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption,class:option.type == 'text' ? 'column' : 'column is-narrow'},[(option.type == 'text')?[_c('b-radio-button',{attrs:{"type":"is-hcc","size":"is-medium","outlined":"","expanded":"","native-value":option.optionId},on:{"copy":function($event){return _vm.copyText()},"input":function($event){return _vm.checkRulesRadioButton()}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkboxSelected),expression:"checkboxSelected"}],staticStyle:{"margin-right":"10px"},attrs:{"id":option.optionId,"type":"checkbox","disabled":_vm.isDisabledButton &&
                          !_vm.checkboxSelected.includes(option.optionId)},domProps:{"value":option.optionId,"checked":Array.isArray(_vm.checkboxSelected)?_vm._i(_vm.checkboxSelected,option.optionId)>-1:(_vm.checkboxSelected)},on:{"input":function($event){return _vm.checkRulesCheckbox(option.optionId, indexOption)},"change":function($event){var $$a=_vm.checkboxSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option.optionId,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkboxSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkboxSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkboxSelected=$$c}}}}),_c('label',{staticClass:"my-radio-image",staticStyle:{"max-height":"300px","max-width":"300px","object-fit":"cover","background-size":"auto"},style:(_vm.getSizeImage(option.optionId) >= 300
                          ? 'width: 100%;'
                          : 'width: auto;'),attrs:{"for":option.optionId}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],staticStyle:{"width":"auto","max-width":"100%"},attrs:{"id":("image-" + (option.optionId)),"alt":""}})])]:_vm._e()],2)}),0),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3836086880)})],1):_vm._e(),(
              _vm.rulesData.checkboxTypeAnswer[0] == true &&
                _vm.rulesData.checkboxTypeAnswer[1] == true
            )?_c('span',[_c('ValidationProvider',{attrs:{"vid":("checkboxtype" + _vm.indexSection + _vm.indexAssessment),"name":"checkbox","rules":("requiredarraybetween:" + (_vm.rulesData.checkboxMinAnswer) + "," + (_vm.rulesData.checkboxMaxAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"columns image-selector is-multiline"},_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption,class:option.type == 'text' ? 'column' : 'column is-narrow'},[(option.type == 'text')?[_c('b-radio-button',{attrs:{"type":"is-hcc","size":"is-medium","outlined":"","expanded":"","native-value":option.optionId},on:{"copy":function($event){return _vm.copyText()},"input":function($event){return _vm.checkRulesRadioButton()}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.checkboxSelected),expression:"checkboxSelected"}],staticStyle:{"margin-right":"10px"},attrs:{"id":option.optionId,"type":"checkbox","disabled":_vm.isDisabledButton &&
                          !_vm.checkboxSelected.includes(option.optionId)},domProps:{"value":option.optionId,"checked":Array.isArray(_vm.checkboxSelected)?_vm._i(_vm.checkboxSelected,option.optionId)>-1:(_vm.checkboxSelected)},on:{"input":function($event){return _vm.checkRulesCheckbox(option.optionId, indexOption)},"change":function($event){var $$a=_vm.checkboxSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=option.optionId,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.checkboxSelected=$$a.concat([$$v]))}else{$$i>-1&&(_vm.checkboxSelected=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.checkboxSelected=$$c}}}}),_c('label',{staticClass:"my-radio-image",staticStyle:{"max-height":"300px","max-width":"300px","object-fit":"cover","background-size":"auto"},style:(_vm.getSizeImage(option.optionId) >= 300
                          ? 'width: 100%;'
                          : 'width: auto;'),attrs:{"for":option.optionId}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],staticStyle:{"width":"auto","max-width":"100%"},attrs:{"id":("image-" + (option.optionId)),"alt":""}})])]:_vm._e()],2)}),0),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3836086880)})],1):_vm._e()])]:_vm._e()],2),(
        _vm.title == 'Introduction Test' &&
          _vm.rulesData.multipleChoiceType == 'Pass/Fail' &&
          this.data.answerOption.length != 0
      )?[(_vm.isAnswerTrue)?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.textTrueAnswer)}})]):(
          !_vm.isAnswerTrue &&
            _vm.checkboxSelected.length >= _vm.rulesData.checkboxMaxAnswer &&
            _vm.rulesData.checkboxTypeAnswer[0] == true &&
            _vm.rulesData.checkboxTypeAnswer[1] == false
        )?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.textFalseAnswer)}})]):(
          !_vm.isAnswerTrue &&
            _vm.checkboxSelected.length >= _vm.rulesData.checkboxMinAnswer &&
            _vm.rulesData.checkboxTypeAnswer[0] == false &&
            _vm.rulesData.checkboxTypeAnswer[1] == true
        )?_c('div',[_c('strong',[_vm._v("JAWABAN ANDA SALAH!")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.textFalseAnswer)}})]):(
          !_vm.isAnswerTrue &&
            _vm.checkboxSelected.length >= _vm.rulesData.checkboxMaxAnswer &&
            _vm.rulesData.checkboxTypeAnswer[0] == true &&
            _vm.rulesData.checkboxTypeAnswer[1] == true
        )?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.textFalseAnswer)}})]):_vm._e()]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
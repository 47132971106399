var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12"},[(_vm.data.optionRules == 'Regular Button')?[_c('ValidationProvider',{attrs:{"vid":("radiobuttontype" + _vm.indexSection + _vm.indexAssessment),"name":"Radio Button","rules":("" + (_vm.data.require ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{style:(_vm.differentiateStyle(_vm.optionData))},[_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12"},[(option.type == 'text')?[_c('b-radio',{attrs:{"type":"is-hcc","native-value":option.optionId},on:{"input":function($event){return _vm.checkRulesRadioButton(option.optionId)}},model:{value:(_vm.radioButtonSelected),callback:function ($$v) {_vm.radioButtonSelected=$$v},expression:"radioButtonSelected"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('b-radio',{attrs:{"type":"is-hcc","native-value":option.optionId},on:{"input":function($event){return _vm.checkRulesRadioButton(option.optionId)}},model:{value:(_vm.radioButtonSelected),callback:function ($$v) {_vm.radioButtonSelected=$$v},expression:"radioButtonSelected"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],staticStyle:{"width":"auto"},attrs:{"alt":"Image"}})])]:_vm._e()],2)])])}),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])],2)]}}],null,false,3473575260)})]:(_vm.data.optionRules == 'Text Inside Button')?[_c('ValidationProvider',{attrs:{"vid":("radiobuttontype" + _vm.indexSection + _vm.indexAssessment),"name":"Radio Button","rules":("" + (_vm.data.require ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"columns is-multiline image-selector"},_vm._l((_vm.optionData),function(option,indexOption){return _c('div',{key:indexOption,class:option.type == 'text' ? 'column' : 'column is-narrow'},[(option.type == 'text')?[_c('b-radio-button',{attrs:{"type":"is-hcc","size":"is-medium","outlined":"","expanded":"","native-value":option.optionId},on:{"input":function($event){return _vm.checkRulesRadioButton(option.optionId)}},model:{value:(_vm.radioButtonSelected),callback:function ($$v) {_vm.radioButtonSelected=$$v},expression:"radioButtonSelected"}},[_vm._v(" "+_vm._s(option.title)+" ")])]:(option.type == 'image')?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.radioButtonSelected),expression:"radioButtonSelected"}],attrs:{"id":option.optionId,"type":"radio"},domProps:{"value":option.optionId,"checked":_vm._q(_vm.radioButtonSelected,option.optionId)},on:{"input":function($event){return _vm.checkRulesRadioButton(option.optionId)},"change":function($event){_vm.radioButtonSelected=option.optionId}}}),_c('label',{staticClass:"my-radio-image",staticStyle:{"max-height":"300px","max-width":"300px","object-fit":"cover","background-size":"auto"},style:(_vm.getSizeImage(option.optionId) >= 300
                      ? 'width: 100%;'
                      : 'width: auto;'),attrs:{"for":option.optionId}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.getUrlFile(option.fileUrl)),expression:"getUrlFile(option.fileUrl)"}],staticStyle:{"width":"auto","max-width":"100%"},attrs:{"id":("image-" + (option.optionId)),"alt":""}})])]:_vm._e()],2)}),0),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}])})]:_vm._e()],2),(
        _vm.title == 'Introduction Test' &&
          _vm.rulesData.multipleChoiceType == 'Pass/Fail' &&
          this.data.answerOption[0] != null
      )?[(_vm.isAnswerTrue)?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.textTrueAnswer)}})]):(!_vm.isAnswerTrue && _vm.radioButtonSelected != null)?_c('div',[_c('div',{domProps:{"innerHTML":_vm._s(_vm.data.textFalseAnswer)}})]):_vm._e()]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }
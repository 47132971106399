<template>
  <div>
    <!-- For Text -->
    {{ data.question }}

    <br />
    <br />

    <!-- For uploading video -->
    <template
      v-if="
        (totalVideoPlayed < 2 && data.questionVideoLimit) ||
          !data.questionVideoLimit
      "
    >
      <div v-for="(file, f) in data.questionFile" :key="f">
        <div class="columns">
          <div class="column is-12">
            <!-- For Displaying Video -->
            <video
              width="320"
              height="240"
              controls
              @ended="endedVideo()"
              disablePictureInPicture
              controlsList="nodownload"
            >
              <source
                v-lazy="getUrlFile(file.url)"
                v-if="
                  type == 'detail' || $route.name == 'editAssessmentSection'
                "
              />
              <source v-lazy="file.url" v-else-if="type == 'form'" />
            </video>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    type: {
      default: () => {},
    },
  },
  data() {
    return {
      totalVideoPlayed: 0,
    }
  },
  methods: {
    getUrlFile(file) {
      return process.env.VUE_APP_BACKEND_URL + file
    },
    endedVideo() {
      this.totalVideoPlayed += 1
    },
  },
}
</script>

<style lang="scss">
video::-webkit-media-controls-timeline {
  display: none;
}
</style>

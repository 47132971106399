var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data.require == true)?_c('span',[_c('span',{staticClass:"message-body-data"},[_vm._v(" Klik tahan pilihan dari kotak sebelah kiri, dan pindahkan ke kotak sebelah kanan. Setelah itu, urutkan pilihan Anda. ")]),(
      _vm.rulesData.rankingTypeAnswer[0] == false &&
        _vm.rulesData.rankingTypeAnswer[1] == true
    )?_c('span',[_c('ValidationProvider',{staticClass:"columns is-multiline",staticStyle:{"margin":"0.1px"},attrs:{"vid":("rankingtype" + _vm.indexSection + _vm.indexAssessment),"name":"Ranking","rules":("requiredarray|checkbox-answermax:" + (_vm.rulesData.rankingMaxAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('draggable',{staticClass:"column is-6 border-ranking-left is-variable list-group",attrs:{"list":_vm.rankingOption,"group":"ranking1"}},_vm._l((_vm.rankingOption),function(ranking,indexRanking){return _c('div',{key:indexRanking,staticStyle:{"padding-bottom":"2%"}},[_c('div',{staticClass:"box-answer"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-11",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(ranking.option)+" ")]),_c('div',{staticClass:"column is-1",staticStyle:{"margin":"auto"}},[_c('b-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"size":"is-small","icon":"share"}})],1)])])])}),0),_c('draggable',{staticClass:"column is-6 border-ranking-right is-variable list-group",attrs:{"group":"ranking1"},model:{value:(_vm.answerRanking),callback:function ($$v) {_vm.answerRanking=$$v},expression:"answerRanking"}},_vm._l((_vm.answerRanking),function(ranking,indexRanking){return _c('div',{key:indexRanking,staticStyle:{"padding-bottom":"2%"}},[_c('div',{staticClass:"box-answer"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-11",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(indexRanking + 1)+". "+_vm._s(ranking.option)+" ")]),_c('div',{staticClass:"column is-1",staticStyle:{"margin":"auto"}},[_c('b-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"size":"is-small","icon":"sort"}})],1)])])])}),0),_c('span',{staticClass:"required column is-12"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3484290388)})],1):(
      _vm.rulesData.rankingTypeAnswer[0] == true &&
        _vm.rulesData.rankingTypeAnswer[1] == false
    )?_c('span',[_c('ValidationProvider',{staticClass:"columns is-multiline",staticStyle:{"margin":"0.1px"},attrs:{"vid":("rankingtype" + _vm.indexSection + _vm.indexAssessment),"name":"Ranking","rules":("requiredarray|checkbox-answermin:" + (_vm.rulesData.rankingMinAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('draggable',{staticClass:"column is-6 border-ranking-left is-variable list-group",attrs:{"list":_vm.rankingOption,"group":"ranking2"}},_vm._l((_vm.rankingOption),function(ranking,indexRanking){return _c('div',{key:indexRanking,staticStyle:{"padding-bottom":"2%"}},[_c('div',{staticClass:"box-answer"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-11",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(ranking.option)+" ")]),_c('div',{staticClass:"column is-1",staticStyle:{"margin":"auto"}},[_c('b-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"size":"is-small","icon":"share"}})],1)])])])}),0),_c('draggable',{staticClass:"column is-6 border-ranking-right is-variable list-group",attrs:{"group":"ranking2"},model:{value:(_vm.answerRanking),callback:function ($$v) {_vm.answerRanking=$$v},expression:"answerRanking"}},_vm._l((_vm.answerRanking),function(ranking,indexRanking){return _c('div',{key:indexRanking,staticStyle:{"padding-bottom":"2%"}},[_c('div',{staticClass:"box-answer"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-11",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(indexRanking + 1)+". "+_vm._s(ranking.option)+" ")]),_c('div',{staticClass:"column is-1",staticStyle:{"margin":"auto"}},[_c('b-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"size":"is-small","icon":"sort"}})],1)])])])}),0),_c('span',{staticClass:"required column is-12"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1601018804)})],1):(
      _vm.rulesData.rankingTypeAnswer[0] == true &&
        _vm.rulesData.rankingTypeAnswer[1] == true
    )?_c('span',[_c('ValidationProvider',{staticClass:"columns is-multiline",staticStyle:{"margin":"0.1px"},attrs:{"vid":("rankingtype" + _vm.indexSection + _vm.indexAssessment),"name":"Ranking","rules":("requiredarray|requiredarraybetween:" + (_vm.rulesData.rankingMinAnswer) + "," + (_vm.rulesData.rankingMaxAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('draggable',{staticClass:"column is-6 border-ranking-left is-variable list-group",attrs:{"list":_vm.rankingOption,"group":"ranking3"}},_vm._l((_vm.rankingOption),function(ranking,indexRanking){return _c('div',{key:indexRanking,staticStyle:{"padding-bottom":"2%"}},[_c('div',{staticClass:"box-answer"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-11",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(ranking.option)+" ")]),_c('div',{staticClass:"column is-1",staticStyle:{"margin":"auto"}},[_c('b-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"size":"is-small","icon":"share"}})],1)])])])}),0),_c('draggable',{staticClass:"column is-6 border-ranking-right is-variable list-group",attrs:{"group":"ranking3"},model:{value:(_vm.answerRanking),callback:function ($$v) {_vm.answerRanking=$$v},expression:"answerRanking"}},_vm._l((_vm.answerRanking),function(ranking,indexRanking){return _c('div',{key:indexRanking,staticStyle:{"padding-bottom":"2%"}},[_c('div',{staticClass:"box-answer"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-11",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(indexRanking + 1)+". "+_vm._s(ranking.option)+" ")]),_c('div',{staticClass:"column is-1",staticStyle:{"margin":"auto"}},[_c('b-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"size":"is-small","icon":"sort"}})],1)])])])}),0),_c('span',{staticClass:"required column is-12"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,740772244)})],1):_vm._e()]):_c('span',[_c('span',{staticClass:"message-body-data"},[_vm._v(" Klik tahan pilihan dari kotak sebelah kiri, dan pindahkan ke kotak sebelah kanan. Setelah itu, urutkan pilihan Anda. ")]),(
      _vm.rulesData.rankingTypeAnswer[0] == false &&
        _vm.rulesData.rankingTypeAnswer[1] == true
    )?_c('span',[_c('ValidationProvider',{staticClass:"columns is-multiline",staticStyle:{"margin":"0.1px"},attrs:{"vid":("rankingtype" + _vm.indexSection + _vm.indexAssessment),"name":"Ranking","rules":("checkbox-answermax:" + (_vm.rulesData.rankingMaxAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('draggable',{staticClass:"column is-6 border-ranking-left is-variable list-group",attrs:{"list":_vm.rankingOption,"group":"ranking4"}},_vm._l((_vm.rankingOption),function(ranking,indexRanking){return _c('div',{key:indexRanking,staticStyle:{"padding-bottom":"2%"}},[_c('div',{staticClass:"box-answer"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-11",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(ranking.option)+" ")]),_c('div',{staticClass:"column is-1",staticStyle:{"margin":"auto"}},[_c('b-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"size":"is-small","icon":"share"}})],1)])])])}),0),_c('draggable',{staticClass:"column is-6 border-ranking-right is-variable list-group",attrs:{"group":"ranking4"},model:{value:(_vm.answerRanking),callback:function ($$v) {_vm.answerRanking=$$v},expression:"answerRanking"}},_vm._l((_vm.answerRanking),function(ranking,indexRanking){return _c('div',{key:indexRanking,staticStyle:{"padding-bottom":"2%"}},[_c('div',{staticClass:"box-answer"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-11",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(indexRanking + 1)+". "+_vm._s(ranking.option)+" ")]),_c('div',{staticClass:"column is-1",staticStyle:{"margin":"auto"}},[_c('b-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"size":"is-small","icon":"sort"}})],1)])])])}),0),_c('span',{staticClass:"required column is-12"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1868870388)})],1):(
      _vm.rulesData.rankingTypeAnswer[0] == true &&
        _vm.rulesData.rankingTypeAnswer[1] == false
    )?_c('span',[_c('ValidationProvider',{staticClass:"columns is-multiline",staticStyle:{"margin":"0.1px"},attrs:{"vid":("rankingtype" + _vm.indexSection + _vm.indexAssessment),"name":"Ranking","rules":("checkbox-answermin:" + (_vm.rulesData.rankingMinAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('draggable',{staticClass:"column is-6 border-ranking-left is-variable list-group",attrs:{"list":_vm.rankingOption,"group":"ranking5"}},_vm._l((_vm.rankingOption),function(ranking,indexRanking){return _c('div',{key:indexRanking,staticStyle:{"padding-bottom":"2%"}},[_c('div',{staticClass:"box-answer"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-11",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(ranking.option)+" ")]),_c('div',{staticClass:"column is-1",staticStyle:{"margin":"auto"}},[_c('b-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"size":"is-small","icon":"share"}})],1)])])])}),0),_c('draggable',{staticClass:"column is-6 border-ranking-right is-variable list-group",attrs:{"group":"ranking5"},model:{value:(_vm.answerRanking),callback:function ($$v) {_vm.answerRanking=$$v},expression:"answerRanking"}},_vm._l((_vm.answerRanking),function(ranking,indexRanking){return _c('div',{key:indexRanking,staticStyle:{"padding-bottom":"2%"}},[_c('div',{staticClass:"box-answer"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-11",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(indexRanking + 1)+". "+_vm._s(ranking.option)+" ")]),_c('div',{staticClass:"column is-1",staticStyle:{"margin":"auto"}},[_c('b-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"size":"is-small","icon":"sort"}})],1)])])])}),0),_c('span',{staticClass:"required column is-12"},[_vm._v(_vm._s(errors[0]))])]}}])})],1):(
      _vm.rulesData.rankingTypeAnswer[0] == true &&
        _vm.rulesData.rankingTypeAnswer[1] == true
    )?_c('span',[_c('ValidationProvider',{staticClass:"columns is-multiline",staticStyle:{"margin":"0.1px"},attrs:{"vid":("rankingtype" + _vm.indexSection + _vm.indexAssessment),"name":"Ranking","rules":("requiredarraybetween:" + (_vm.rulesData.rankingMinAnswer) + "," + (_vm.rulesData.rankingMaxAnswer))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('draggable',{staticClass:"column is-6 border-ranking-left is-variable list-group",attrs:{"list":_vm.rankingOption,"group":"ranking6"}},_vm._l((_vm.rankingOption),function(ranking,indexRanking){return _c('div',{key:indexRanking,staticStyle:{"padding-bottom":"2%"}},[_c('div',{staticClass:"box-answer"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-11",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(ranking.option)+" ")]),_c('div',{staticClass:"column is-1",staticStyle:{"margin":"auto"}},[_c('b-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"size":"is-small","icon":"share"}})],1)])])])}),0),_c('draggable',{staticClass:"column is-6 border-ranking-right is-variable list-group",attrs:{"group":"ranking6"},model:{value:(_vm.answerRanking),callback:function ($$v) {_vm.answerRanking=$$v},expression:"answerRanking"}},_vm._l((_vm.answerRanking),function(ranking,indexRanking){return _c('div',{key:indexRanking,staticStyle:{"padding-bottom":"2%"}},[_c('div',{staticClass:"box-answer"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-11",staticStyle:{"word-wrap":"break-word"}},[_vm._v(" "+_vm._s(indexRanking + 1)+". "+_vm._s(ranking.option)+" ")]),_c('div',{staticClass:"column is-1",staticStyle:{"margin":"auto"}},[_c('b-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"size":"is-small","icon":"sort"}})],1)])])])}),0),_c('span',{staticClass:"required column is-12"},[_vm._v(_vm._s(errors[0]))])]}}])})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
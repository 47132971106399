<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="columns">
          <div class="column is-2"></div>
          <div class="column is-8">
            <template v-if="data.min == 0">
              <div class="columns">
                <div
                  v-for="(number, index) in data.max + 1"
                  :key="index"
                  class="column"
                >
                  <b-field grouped position="is-centered">{{ index }}</b-field>
                </div>
              </div>
            </template>
            <template v-else-if="data.min == 1">
              <div class="columns">
                <div
                  v-for="(number, index) in data.max"
                  :key="index"
                  class="column"
                >
                  <b-field grouped position="is-centered">{{ number }}</b-field>
                </div>
              </div>
            </template>
          </div>
          <div class="column is-2"></div>
        </div>
        <div class="columns">
          <div class="column is-2">
            <div class="level">
              <div class="level-left"></div>
              <div class="level-right">
                {{ data.labelMin }}
              </div>
            </div>
          </div>
          <div class="column is-8">
            <template v-if="data.min == 0">
              <ValidationProvider
                :vid="`linearscaletype${indexSection}${indexAssessment}`"
                name="Linear Scale"
                :rules="{ required: data.require }"
                v-slot="{ errors }"
              >
                <div class="columns">
                  <div
                    v-for="(number, index) in data.max + 1"
                    :key="index"
                    class="column"
                  >
                    <b-field grouped position="is-centered">
                      <b-radio
                        class="scale-width"
                        type="is-hcc"
                        v-model="linearScale.option"
                        :native-value="index"
                      ></b-radio>
                    </b-field>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </template>
            <template v-else-if="data.min == 1">
              <ValidationProvider
                :vid="`linearscaletype${indexSection}${indexAssessment}`"
                name="Linear Scale"
                :rules="{ required: data.require }"
                v-slot="{ errors }"
              >
                <div class="columns">
                  <div
                    v-for="(number, index) in data.max"
                    :key="index"
                    class="column"
                  >
                    <b-field grouped position="is-centered">
                      <b-radio
                        class="scale-width"
                        type="is-hcc"
                        v-model="linearScale.option"
                        :native-value="number"
                      ></b-radio>
                    </b-field>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </template>
          </div>
          <div class="column is-2">{{ data.labelMax }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {}
    },
    linearScale: {
      default: () => {}
    },
    indexSection: {
      default: () => {}
    },
    indexAssessment: {
      default: () => {}
    }
  }
};
</script>
<style>
.scale-width {
  width: 10px !important;
}
</style>
